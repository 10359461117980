import React from 'react'
import styled from 'styled-components'
import Select from 'react-select'

import axios from 'axios'
import {Formik, withFormik, Field, FieldArray, getIn} from 'formik'
import * as Yup from 'yup'
import Recaptcha from 'react-recaptcha'
import {toast} from 'react-toastify'
import Toastify from './Toastify'
import useSSR from 'use-ssr'

import {Debug} from '../lib/formik-debug'

import {colors, font, fontfamily} from '../styles/variables'

const {isBrowser} = useSSR()

const StyledSelect = styled(props => <Select {...props} />)`
  font-size: 1.6rem;
  &.is-invalid > div{
    border-color: ${colors.red};
  }
`

const Heading = styled.span`
  text-align: left;
  display: block;
  font-family: ${fontfamily.jaRounded};
  font-size: 2.0rem;
  margin-bottom: 2.4rem;
  padding-top: 3.6rem;
  color: ${colors.navy};
  position: relative;
  padding-bottom: 0.8rem;
  font-weight: 700;

  & :after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${colors.yellow};
    height: 2px;
    width: 100%;
    border-radius: 4px;
  }
`
const SubHeading = styled.span`
  text-align: left;
  display: block;
  font-family: ${fontfamily.jaRounded};
  font-size: 1.8rem;
  margin-bottom: 2.4rem;
  padding-top: 3.6rem;
  color: ${colors.navy};
  position: relative;
  padding-bottom: 0.8rem;
  font-weight: 700;
`

const Form = styled.form`
  display: block;
  margin: 2rem 1.6rem;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3.2rem;
  /* &.name,
  &.email{
    width: 30rem;
  } */
  &.tel{
    width: 18rem;
  }
  &.year,
  &.month{
    margin-right: 2rem;
    margin-bottom: 0;
  }
  &.month,
  &.day{
    width: 8rem;
    margin-bottom: 0;
  }
  &.year{
    width: 12rem;
  }
`

const Student = styled.div`
  display: block;
  margin: 0 0 2.4rem 0;
  padding-bottom: 2.4rem ;
  border-radius: 4px;
  &:not(:last-child){
    border-bottom: 1px solid ${colors.darkGray};
  }
`

const Birthday = styled.div`
  display: flex;
  flex-direction: row;
`

const Label = styled.label`
  font-size: ${props => props.small ? '1.2rem' : '1.6rem'};
  font-family: ${fontfamily.jaRounded};
  color: ${colors.black};
  margin-bottom: 0.4rem;
  font-weight: 500;
`

const Small = styled.small`
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0 0 0 0.8rem;
  color: ${colors.lightNavy};
`

const Input = styled(props => <Field {...props} />)`
  color: ${colors.black};
  padding: 0.6rem 1.2rem;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid ${colors.lightNavy};
  letter-spacing: 0.05rem;
  font-size: 1.6rem;
  &.is-invalid{
    border-color: ${colors.red};
  }
`

const Textarea = styled.textarea`
  padding: 0.6rem 1.2rem;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid ${colors.lightNavy};
  letter-spacing: 0.05rem;
  font-size: 1.6rem;
  min-height: 20rem;
  &.is-invalid{
    border-color: ${colors.red};
  }
`

const InvalidFeedbck = styled.span`
  color: ${colors.red};
  font-size: 1.4rem;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.student ? 0 : '4rem'};
`

const Button = styled.button`
  font-family: ${fontfamily.jaRounded};
  font-size: 1.6rem;
  display: block;
  border-radius: 100px;
  border: 2px ${colors.blue} solid;
  color: ${colors.blue};
  text-decoration: none;
  text-align: center;
  width: auto;
  padding: 0.6rem 2.4rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
  cursor: pointer;
  &:disabled{
    color: ${colors.lightNavy};
    border-color: ${colors.lightNavy};
    box-shadow: none;
    cursor: default;
  }
`

const ButtonRemove = styled.button`
  font-family: ${fontfamily.jaRounded};
  font-size: 1.4rem;
  display: block;
  color: ${colors.red};
  text-decoration: none;
  text-align: center;
  width: auto;
  padding: 0.2rem 0.4rem;
  margin-top: 2.4rem;
  background-color: white;
  cursor: pointer;
  border: none;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: ${colors.red};
    height: 1px;
  }
`
const Message = styled.div`
  font-size: 1.6rem;
`
const Error = styled.p`
  color: ${colors.red};
  font-size: 1.6rem;
`

const grades = [
  {values: 'prePreschool', label: '未就園(3歳未満)'},
  {values: 'preschool', label: '幼児部'},
  {values: 'elementarySchool', label: '小学部'},
  {values: 'midSchool', label: '中学部'},
  {values: 'highSchool', label: '高等部'}
]

const locations = [
  {value: 'englewood-cliffs', label: 'イングルウッド・クリフス - NJ'},
  {value: 'fort-lee', label: 'フォート・リー - NJ'},
  {value: 'manhattan', label: 'マンハッタン - NY'},
  {value: 'port-washington', label: 'ポート・ワシントン - NY'},
  {value: 'undecided', label: '未定'}
]

const terms = [
  {values: 'fulltime', label: '全日制(平日)'},
  {values: 'saturday', label: 'サタデー(土曜日)'},
  {values: 'sunday', label: 'サンデー(日曜日)'},
  {values: 'after', label: 'アフター'},
  {values: 'undecided', label: '未定'}
]

let recaptchaInstance

const executeCaptcha = () => {
  recaptchaInstance.execute()
}

const resetRecaptcha = () => {
  recaptchaInstance.reset()
}

const ErrorMessage = ({name}) => (
  <Field
    name={name}
    render={({form}) => {
      const error = getIn(form.errors, name)
      const touch = getIn(form.touched, name)
      return touch && error ? <InvalidFeedbck>{error}</InvalidFeedbck> : null
    }}
  />
)

const notifySuccess = () => toast.success('送信完了。\n折り返しご連絡いたしますのでしばらくお待ちください。', {containerId: 'Success'})
const notifyError = () => toast.error('送信に失敗しました。\nページを再読込してから、もう一度送信してください。', {containerId: 'Error'})

const InnerForm = ({
  values,
  touched,
  status,
  errors,
  dirty,
  setStatus,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  handleChange,
  handleBlur,
  handleSubmit,
  handleReset,
  submitCount
}) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup className='name'>
      <Heading>保護者の連絡先</Heading>
      <Label>保護者氏名 <Small>(必須)</Small></Label>
      <Input
        name='name'
        type='text'
        className={`form-control ${errors.name && touched.name && 'is-invalid'}`}
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder='山田太郎'
      />
      <ErrorMessage name='name' />
    </FormGroup>

    <FormGroup className='email'>
      <Label>メールアドレス<Small>(必須)</Small></Label>
      <Input
        name='email'
        type='email'
        className={`form-control ${errors.email && touched.email && 'is-invalid'}`}
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder='name@example.com'
      />
      <ErrorMessage name='email' />
    </FormGroup>

    <FormGroup className='tel'>
      <Label>電話番号</Label>
      <Input
        name='tel'
        type='tel'
        className={`form-control ${errors.tel && touched.tel && 'is-invalid'}`}
        value={values.tel}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder='0123456789'
      />
    </FormGroup>

    <FieldArray
      name='students'
      render={arrayHelpers => (
        <>
          <Heading>幼児・児童・生徒の情報</Heading>
          <div>
            {values.students.map((student, index) => (
              <Student key={index}>
                {values.students.length > 1 && <SubHeading>幼児・児童・生徒 入力欄 ({index + 1})</SubHeading> }
                <FormGroup className='name'>
                  <Label>氏名<Small>(必須)</Small></Label>
                  <Input
                    name={`students.${index}.studentName`}
                    type='text'
                    className={(getIn(errors, `students.${index}.studentName`) && getIn(touched, `students.${index}.studentName`)) && 'is-invalid'}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder='山田一郎'
                  />
                  <ErrorMessage name={`students.${index}.studentName`} />
                </FormGroup>

                <FormGroup>
                  <Label>生年月日<Small>(必須)</Small></Label>
                  <Birthday>
                    <FormGroup className='year'>
                      <Label small>年(西暦)</Label>
                      <Input
                        name={`students.${index}.studentBirthdayYear`}
                        type='number'
                        pattern='\d*'
                        className={(getIn(errors, `students.${index}.studentBirthdayYear`) && getIn(touched, `students.${index}.studentBirthdayYear`)) && 'is-invalid'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='2001'
                        min='2001'
                      />
                    </FormGroup>
                    <FormGroup className='month'>
                      <Label small>月</Label>
                      <Input
                        name={`students.${index}.studentBirthdayMonth`}
                        type='number'
                        pattern='\d*'
                        className={(getIn(errors, `students.${index}.studentBirthdayMonth`) && getIn(touched, `students.${index}.studentBirthdayMonth`)) && 'is-invalid'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='10'
                        min='1'
                        max='12'
                      />
                    </FormGroup>
                    <FormGroup className='day'>
                      <Label small>日</Label>
                      <Input
                        name={`students.${index}.studentBirthdayDay`}
                        type='number'
                        pattern='\d*'
                        className={(getIn(errors, `students.${index}.studentBirthdayDay`) && getIn(touched, `students.${index}.studentBirthdayDay`)) && 'is-invalid'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder='22'
                        min='1'
                        max='31'
                      />
                    </FormGroup>
                  </Birthday>
                  <ErrorMessage name={`students.${index}.studentBirthdayMonth`} />
                  <ErrorMessage name={`students.${index}.studentBirthdayDay`} />
                  <ErrorMessage name={`students.${index}.studentBirthdayYear`} />
                </FormGroup>

                <FormGroup>
                  <Label>学部<Small>(必須・複数の場合は第一希望を入力)</Small></Label>
                  <StyledSelect
                    placeholder='クリックで選択'
                    options={grades}
                    className={(getIn(errors, `students.${index}.studentGrade`) && getIn(touched, `students.${index}.studentGrade`)) && 'is-invalid'}
                    onBlur={() => setFieldTouched(`students.${index}.studentGrade`, true)}
                    onChange={value => setFieldValue(`students.${index}.studentGrade`, value.label)}
                  />
                  <ErrorMessage name={`students.${index}.studentGrade`} />
                </FormGroup>

                <FormGroup>
                  <Label>課程(登校日)<Small>(必須・複数の場合は第一希望を入力)</Small></Label>
                  <StyledSelect
                    placeholder='クリックで選択'
                    options={terms}
                    className={(getIn(errors, `students.${index}.studentTerm`) && getIn(touched, `students.${index}.studentTerm`)) && 'is-invalid'}
                    onBlur={() => setFieldTouched(`students.${index}.studentTerm`, true)}
                    onChange={value => setFieldValue(`students.${index}.studentTerm`, value.label)}
                  />
                  <ErrorMessage name={`students.${index}.studentTerm`} />
                </FormGroup>

                <FormGroup>
                  <Label>エリア<Small>(必須・複数の場合は第一希望を入力)</Small></Label>
                  <StyledSelect
                    placeholder='クリックで選択'
                    options={locations}
                    className={(getIn(errors, `students.${index}.studentLocation`) && getIn(touched, `students.${index}.studentLocation`)) && 'is-invalid'}
                    onBlur={() => setFieldTouched(`students.${index}.studentLocation`, true)}
                    onChange={value => setFieldValue(`students.${index}.studentLocation`, value.label)}
                  />
                  <ErrorMessage name={`students.${index}.studentLocation`} />
                </FormGroup>

                {values.students.length > 1 &&
                <ButtonWrapper>
                  <ButtonRemove type='button' onClick={() => arrayHelpers.remove(index)}>
                  幼児・児童・生徒 入力欄 ({index + 1})を削除
                  </ButtonRemove>
                </ButtonWrapper>
                }
                {index === values.students.length - 1 &&
                <ButtonWrapper student>
                  <Button
                    type='button'
                    onClick={() => arrayHelpers.push({
                      studentName: '',
                      studentBirthdayDay: '',
                      studentBirthdayMonth: '',
                      studentBirthdayYear: '',
                      studentGrade: '',
                      studentLocation: '',
                      studentTerm: ''
                    })}
                  >
             幼児・児童・生徒の入力欄を追加
                  </Button>
                </ButtonWrapper>
                }
              </Student>
            ))}
          </div>
        </>
      )}
    />

    <FormGroup>
      <Heading>メッセージ<Small>(必須)</Small></Heading>
      <Textarea
        name='message'
        type='textarea'
        className={`form-control ${errors.message && touched.message && 'is-invalid'}`}
        value={values.message}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <ErrorMessage name='message' />
    </FormGroup>

    <FormGroup>
      {isBrowser &&
      <>
        <Recaptcha
          ref={(e) => { recaptchaInstance = e }}
          size='invisible'
          sitekey='6LdkcsEUAAAAAIAXuMITlUvoJ35-13alVA29ckCS'
          verifyCallback={(response) => {
            setFieldValue('recaptcha', response)
            handleSubmit()
          }}
          expiredCallback={(response) => {
            setFieldValue('recaptcha', '')
            handleSubmit()
          }}
        />
        <ErrorMessage name='recaptcha' />
      </>
      }
    </FormGroup>

    { ((submitCount > 0 && !!Object.keys(errors).length) && (errors.hasOwnProperty('name') || errors.hasOwnProperty('email') || errors.hasOwnProperty('message') || errors.hasOwnProperty('students'))) &&
      <Error>未入力の項目、間違った入力内容がござます。<br /> 入力欄に戻り赤く囲われた箇所をご確認ください。</Error>
    }

    <ButtonWrapper>
      <Button type='button'
        onClick={executeCaptcha}
        className='btn btn-outline-primary'
        disabled={isSubmitting || (status && status.state === 'Success')}
      >
        {isSubmitting ? '送信中...' : (status && status.state === 'Success') ? '送信済み' : '送信する'}
      </Button>
    </ButtonWrapper>
    {/* <Debug /> */}
    <Toastify
      enableMultiContainer
      containerId={'Success'}
      position='top-right'
      autoClose={8000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      pauseOnHover
      className='myToast'
    />
    <Toastify
      enableMultiContainer
      containerId={'Error'}
      position='top-right'
      autoClose={8000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      pauseOnHover
      className='myToast'
    />

  </Form>
)

const ApplicationForm = withFormik({
  mapPropsToValues: () => ({
    name: '',
    tel: '',
    email: '',
    message: '',
    recaptcha: '',
    students: [ {
      studentName: '',
      studentBirthdayDay: '',
      studentBirthdayMonth: '',
      studentBirthdayYear: '',
      studentGrade: '',
      studentLocation: '',
      studentTerm: ''
    }]
  }),
  // mapPropsToValues: () => ({
  //   name: '1',
  //   tel: '1',
  //   email: 'test@test.test',
  //   message: '1',
  //   recaptcha: '',
  //   students: [ {
  //     studentName: '1',
  //     studentBirthdayDay: 10,
  //     studentBirthdayMonth: 10,
  //     studentBirthdayYear: 2010,
  //     studentGrade: '1',
  //     studentLocation: '1',
  //     studentTerm: '1'
  //   }]
  // }),

  validationSchema: Yup.object().shape({
    name: Yup.string().required('保護者氏名を入力してください'),
    email: Yup.string().email('無効なメールアドレスです。入力内容をご確認ください。').required('メールアドレスを入力してください'),
    message: Yup.string().required('メッセージを入力してください'),
    recaptcha: Yup.string().required('ロボットの可能性があるため送信できませんでした。再送信してください。'),
    students: Yup.array()
      .of(
        Yup.object().shape({
          studentName: Yup.string().required('お子様氏名を入力してください'),
          studentBirthdayDay: Yup.number().typeError('半角数字で入力ください').min(1, '無効な値です。誕生日を半角数字で入力ください').max(31, '無効な値です。半角数字で入力ください').required('誕生日を入力してください'),
          studentBirthdayMonth: Yup.number().typeError('半角数字で入力ください').min(1, '無効な値です。誕生月を半角数字で入力ください').max(12, '無効な値です。半角数字で入力ください').required('誕生月を入力してください'),
          studentBirthdayYear: Yup.number().typeError('半角数字で入力ください').min(2000, '無効な値です。誕生年を半角数字で入力ください').max(2100, '無効な値です。半角数字で入力ください').required('誕生年を入力してください'),
          studentGrade: Yup.string().required('学部を選択してください。'),
          studentLocation: Yup.string().required('エリアを選択してください。'),
          studentTerm: Yup.string().required('課程(登校日)を選択してください。')
        })
      )
  }),

  handleSubmit:
   (values, {
     setSubmitting, resetForm, setStatus
   }) => {
     setStatus({state: 'Submitting'})
     setTimeout(() => {
       axios.request({
         method: 'POST',
         baseURL: '/api/message/admissions',
         headers: {
           'Content-Type': 'application/json; charset=UTF-8'
         },
         data: JSON.stringify({
           name: values.name,
           email: values.email,
           tel: values.tel,
           students: values.students,
           message: values.message
         })
       })
         .then(() => {
           //  resetForm()
           notifySuccess()
           setSubmitting(false)
           setStatus({state: 'Success'})
         }).catch(() => {
           notifyError()
           setSubmitting(false)
           setStatus({state: 'Error'})
         })
     }, 1200)
   }
})(InnerForm)

export default ApplicationForm
