import React from 'react'
import styled from 'styled-components'

import {buildImageObj, cn, toPlainText} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import {colors, font, fontfamily} from '../styles/variables'

import Heading from './heading'
import Button from './button'
import ApplicationFrom from './application-form'
import {Link} from 'gatsby'

const Wrapper = styled.section`
  display: block;
  padding: 4rem 0;
  @media (min-width: 768px) {
    margin: 0 auto;
    max-width: 680px;
  }
`

const ImageWrapper = styled.div`
  display: block;
  margin: 0 2.4rem 4rem 2.4rem;
`

const Image = styled.img`
  display: block;
  border-radius: 5px;
`

const Text = styled.div`
  margin: 0 2.4rem 4rem 2.4rem;
`

class Application extends React.Component {
  render () {
    return (
      <Wrapper>
        <Heading title='入園・見学お問い合わせ' titleEn='Application' />
        <Text>
          <p>入園・見学をご希望の方は下記フォームよりお申し込み・ご質問ください。担当が折り返しご連絡差し上げます。</p>
          <p>学籍登録用紙などの申請書をお探しの方は、<Link to='/downloads/'>申請書・学習ツールダウンロードページ</Link>へアクセスください。
          </p>
          <p>
          その他お問い合わせは<Link to='/contact/'>お問い合わせページ</Link>よりご連絡ください。
          </p>
          {this.props._rawBody && <PortableText blocks={this.props._rawBody} />}
        </Text>
        <ApplicationFrom />
      </Wrapper>
    )
  }
}
export default Application
